.scout-form-select {
  @extend .w-100;
  @extend .px-8;
  border-bottom: 2px solid rgba(var(--ion-color-primary-rgb), 0.55);
  background: rgba(var(--ion-color-primary-rgb), 0.1);
  transition: all .15s ease;
  font-size: var(--scout-font-size-sm);

  &.has-focus {
    border-bottom-color: var(--ion-color-primary);
    background: rgba(var(--ion-color-primary-rgb), 0.15);
    transition: all .15s ease;
  }

  &.ng-touched.ng-invalid {
    border-bottom-color: var(--ion-color-danger);
    background: rgba(var(--ion-color-danger-rgb), 0.12);
    transition: all .15s ease;

    & ~ ion-label {
      color: var(--ion-color-danger);
    }
  }
}

.scout-form-select-dialog {
  .alert-head h2 {
    @extend .font-is-semibold;
    font-size: var(--scout-font-size-sm);
  }

  .alert-radio-group {
    .alert-radio-label {
      @extend .font-is-semibold;
    }
  }

  button.alert-button {
    color: var(--ion-color-primary-shade);

    &.alert-button-role-cancel {
      color: var(--ion-color-medium-tint);
    }

    &:not(.alert-button-role-cancel) {
      @extend .font-is-semibold;
    }
  }
}

// radio-buttons

ion-radio-group ion-item {
  --background: var(--ion-color-light);
  --border-radius: 4px;

  ion-radio {
    border-radius: 50%;
    background-color: var(--ion-color-primary-contrast);
  }
}
