.modal {
  &:not(.full-screen)::part(content) {
    --border-radius: var(--scout-border-radius-md);
    --min-width: 90vw;
    --max-width: 90vw;
    --min-height: 65vh;
    --max-height: 80vh;
  }

  &--simple::part(content) {
    align-self: flex-start;
    height: auto;
    margin-top: 75px;
  }

  &--score::part(content) {
    .list-title {
      color: rgba(var(--ion-color-medium-rgb), 0.6);
    }

    .score-list {
      ion-label {
        line-height: 1.25;
        align-items: flex-start;
        white-space: pre-wrap;
        margin: 5% 0;
        height: 90%;
      }

      ion-text {
        align-self: baseline;
      }
    }
  }

}
