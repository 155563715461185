scout-button ion-button  {
  fa-icon[slot="start"] {
    @extend .mr-4;
  }

  span {
    @extend .font-is-bold;
  }

  fa-icon[slot="end"] {
    margin-left: auto;
  }
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.button-link {
  background: rgba(var(--ion-color-primary-rgb), 0.1);
  color: var(--ion-color-primary);
  border-radius: var(--scout-border-radius-sm);
  text-decoration: none;
  flex-wrap: nowrap;
  @extend .py-12;
  @extend .px-16;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    background: rgba(var(--ion-color-primary-rgb), 0.15);
  }

  &--left-icon {
    @include size(var(--scout-icon-circle-size));
    background: var(--ion-color-primary-contrast);
    min-width: var(--scout-icon-circle-size);
    min-height: var(--scout-icon-circle-size);
    border-radius: var(--scout-border-radius-circle);
  }

  &--is-disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: rgba(var(--ion-color-dark-rgb), 0.7);

    &--label {
      @extend .font-size-xs;
      line-height: 1.45;

    }
  }
}

ion-fab-button,
ion-button {
  span, fa-icon {
    pointer-events: none;
  }
}
