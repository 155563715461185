.d-inline-flex {
  display: inline-flex !important;
}

.d-flex {
  display: flex !important;

  &--center-center {
    @extend .d-flex;
    justify-content: center;
    align-items: center;
  }
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.gap-16 {
  gap: 16px;
}
