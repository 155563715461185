ion-searchbar {
  padding-left: 0 !important;
  padding-right: 0 !important;

  input.searchbar-input {
    --background: rgba(var(--ion-color-primary-rgb), 0.1);
    --box-shadow: none;

    border-bottom: 2px solid rgba(var(--ion-color-primary-rgb), 0.55) !important;
    transition: all .15s ease !important;
    font-size: var(--scout-font-size-sm) !important;
  }

  .searchbar-input-container {
    --clear-button-color: var(--ion-color-primary) !important;
    --icon-color: var(--ion-color-primary) !important;
  }

  &.searchbar-has-focus {
    input.searchbar-input {
      --background: rgba(var(--ion-color-primary-rgb), 0.15);
      border-bottom-color: var(--ion-color-primary) !important;
    }
  }
}
