fa-icon.colored-icon {
  font-size: var(--scout-big-colored-icon-size);
  --fa-secondary-opacity: 1.0;
  --fa-primary-color: var(--ion-color-primary-tint);
  --fa-secondary-color: var(--ion-color-light);

  &.is-small {
    font-size: var(--scout-big-colored-icon-size-sm);
  }

  &.switch-colors {
    --fa-primary-color: var(--ion-color-light);
    --fa-secondary-color: var(--ion-color-primary-tint);
  }
}
