:root {
  --scout-font-size-base: 1rem;
  --scout-font-size-xl: calc(var(--scout-font-size-base) * 1.5);
  --scout-font-size-lg: calc(var(--scout-font-size-base) * 1.25);
  --scout-font-size-md: var(--scout-font-size-base);
  --scout-font-size-sm: calc(var(--scout-font-size-base) * .875);
  --scout-font-size-xs: calc(var(--scout-font-size-base) * .75);

  --scout-font-size-title: var(--scout-font-size-sm);
}
