.alert {
  &--icon {
    @include size(var(--scout-icon-circle-size));
    max-width: var(--scout-icon-circle-size);
    flex: 1 0 auto;
    border-radius: var(--scout-border-radius-circle);
  }
}

.alert {
  &--error {
    background: rgba(var(--ion-color-danger-rgb), 0.12);
    color: var(--ion-color-danger);

    .alert--icon {
      background: rgba(var(--ion-color-danger-rgb), 0.08);
      color: var(--ion-color-danger-shade);
    }

    .alert--text a {
      color: var(--ion-color-danger-shade);
    }
  }

  &--info {
    background: rgba(var(--ion-color-blue-rgb), 0.1);
    color: var(--ion-color-dark);

    .alert--icon {
      background: rgba(var(--ion-color-blue-rgb), 0.08);
      color: var(--ion-color-blue);
    }

    .alert--text a {
      color: var(--ion-color-blue-shade);
    }
  }
}
