ion-slides.tutorial-slider.swiper-container.swiper-container-horizontal {
  ion-slide {
    scout-button {
      @extend .p-16;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  > .swiper-pagination.swiper-pagination-bullets {
    bottom: 20vh;
  }
}
