/**
generates spacing-helper-classes like
following this schema:

[p|m][""|t|b|l|r|x|y]-[""|sm|md|lg|xl]-[0|4|8|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80]

e.g
m-0 => margin: 0
pt-sm-12 => padding-top: 12px on devices where min-width is matching the sm-breakpoint
or
py-12 => padding-top: 12px and padding-bottom: 12px
**/

$sides: (
  "": "",
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
  "x": "",
  "y": "",
);

@mixin generateSpacer($selector, $property, $space, $sideName) {
  .m#{$selector} {
    @if $sideName == 'y' {
      margin-top: #{$space}px !important;
      margin-bottom: #{$space}px !important;
    } @else if $sideName == 'x' {
      margin-left: #{$space}px !important;
      margin-right: #{$space}px !important;
    } @else {
      margin#{$property}: #{$space}px !important;
    }
  }

  .p#{$selector} {
    @if $sideName == 'y' {
      padding-top: #{$space}px !important;
      padding-bottom: #{$space}px !important;
    } @else if $sideName == 'x' {
      padding-left: #{$space}px !important;
      padding-right: #{$space}px !important;
    } @else {
      padding#{$property}: #{$space}px !important;
    }
  }

  .negative-m#{$selector} {
    @if $sideName == 'y' {
      margin-top: -#{$space}px !important;
      margin-bottom: -#{$space}px !important;
    } @else if $sideName == 'x' {
      margin-left: -#{$space}px !important;
      margin-right: -#{$space}px !important;
    } @else {
      margin#{$property}: -#{$space}px !important;
    }
  }
}

@mixin generateAutoSpacer($selector, $property, $sideName) {
  .m#{$selector} {
    @if $sideName == 'y' {
      margin-top: auto !important;
      margin-bottom: auto !important;
    } @else if $sideName == 'x' {
      margin-left: auto !important;
      margin-right: auto !important;
    } @else {
      margin#{$property}: auto !important;
    }
  }
}

@each $breakName, $breakValue in $breakpoints {
  @each $sideName, $sideValue in $sides {
    $property: if($sideName == '', '', -#{$sideValue});
    $selector: if($breakName != "", #{$sideName}-#{$breakName}-auto, #{$sideName}-auto);
    $autoSelector: if($breakName != "", #{$sideName}-#{$breakName}-auto, #{$sideName}-auto);

    @include generateAutoSpacer($autoSelector, $property, $sideName);

    @for $i from 0 through 20 {
      $space: $i * 4;
      $selector: if($breakName != "", #{$sideName}-#{$breakName}-#{$space}, #{$sideName}-#{$space});

      @if $breakName != "" {
        @media (min-width: $breakValue) {
          @include generateSpacer($selector, $property, $space, $sideName);
        }
      } @else {
        @include generateSpacer($selector, $property, $space, $sideName);
      }
    }
  }
}


