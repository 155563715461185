:root {
  --available-cluster-color: var(--ion-color-success-tint);
  --available-cluster-bg-color: var(--ion-color-tertiary-tint);

  --cluster-color: var(--ion-color-success-tint);
  --cluster-bg-color: var(--ion-color-tertiary-tint);

  --pending-cluster-color: white;
  --pending-cluster-bg-color: var(--ion-color-warning-light);

  --success-cluster-color: white;
  --success-cluster-bg-color: var(--ion-color-success-light);

  --fail-cluster-color: var(--ion-color-danger-shade);
  --fail-cluster-bg-color: var(--ion-color-danger-light);

  --scouts-cluster-color: var(--ion-color-secondary-contrast);
  --scouts-cluster-bg-color: var(--ion-color-secondary-shade);
}

.map-cluster {
  @extend .font-is-semibold;
  @extend .font-size-sm;
  @extend .d-flex--center-center;
}
