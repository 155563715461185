form {
  transition: opacity .15s ease;
  opacity: 1;

  &.is-disabled {
    opacity: 0.75;
    transition: opacity .15s ease;
    pointer-events: none;
  }
}

.horizontal-form {
  @extend .d-flex;
  @extend .flex-column;
  min-height: 100%;
  justify-content: space-between;
}
