.font-is-light {
  font-weight: 300 !important;
}

.font-is-regular {
  font-weight: 400 !important;
}

.font-is-semibold {
  font-weight: 600 !important;
}

.font-is-bold {
  font-weight: 700 !important;
}

.font-is-extra-bold {
  font-weight: 800 !important;
}

.font-size-xs {
  font-size: var(--scout-font-size-xs) !important;
}

.font-size-sm {
  font-size: var(--scout-font-size-sm) !important;
}

.font-size-md {
  font-size: var(--scout-font-size-md) !important;
}

.font-size-lg {
  font-size: var(--scout-font-size-lg) !important;
}

.more-letter-spacing {
  letter-spacing: 0.5px !important;
}

.is-continuous-text {
  line-height: 1.45 !important;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
