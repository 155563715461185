.form-field-group {
  @extend .d-flex;
  @extend .flex-column;

  ion-label {
    order: 0;
  }

  .input-group,
  scout-form-choice-chips,
  scout-form-textarea,
  scout-form-input {
    order: 1;
  }

  &--hint {
    order: 2;
  }

  scout-form-error-message {
    order: 3;
  }
}
