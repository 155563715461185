label.choice-chip {
  user-select: none;
  cursor: pointer;
  border-radius: var(--scout-border-radius-sm);

  input {
    opacity: 0;
    @include size(0);

    &:checked ~ span {
      background-color: rgba(var(--ion-color-primary-rgb), 0.25);
      color: var(--ion-color-dark);
      transition: color .15s ease, background-color .15s .15s ease;
    }

    &:focus ~ span,
    &:checked:focus ~ span {
      background-color: rgba(var(--ion-color-primary-rgb), 0.3);
      color: var(--ion-color-dark);
      transition: color .15s ease, background-color .15s ease;
      outline: rgba(var(--ion-color-primary-rgb), 0.3) 2px solid;
    }
  }

  span {
    background-color: rgba(var(--ion-color-primary-rgb), 0.1);
    transition: color .15s ease, background-color .15s ease;
    color: var(--ion-color-primary);
    @include size(100%);
    @extend .py-12;
    @extend .px-16;
  }
}
