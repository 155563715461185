.form-field-group {
  ion-label.label-stacked {
    @extend .mb-12;
    color: var(--ion-color-dark);
  }

  ion-textarea {
    border-bottom: 2px solid rgba(var(--ion-color-primary-rgb), 0.55);
    --background: rgba(var(--ion-color-primary-rgb), 0.1);
    transition: all .15s ease;
    font-size: var(--scout-font-size-sm);

    &.has-focus {
      border-bottom-color: var(--ion-color-primary);
      background: rgba(var(--ion-color-primary-rgb), 0.15);
      transition: all .15s ease;
    }

    &.ng-touched.ng-invalid {
      border-bottom-color: var(--ion-color-danger);
      background: rgba(var(--ion-color-danger-rgb), 0.12);
      transition: all .15s ease;

      & ~ ion-label {
        color: var(--ion-color-danger);
      }
    }

    textarea {
      min-height: var(--scout-textarea-min-height);
    }
  }
}
