:root {
  --ion-font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;

  --scout-form-uploader-height: 220px;
  --scout-big-colored-icon-size: 56px;
  --scout-big-colored-icon-size-sm: 32px;
  --scout-border-radius-circle: 50%;
  --scout-border-radius-md: 8px;
  --scout-border-radius-sm: 4px;
  --scout-icon-circle-size: 32px;
  --scout-map-info-window-margin: 8px;
  --scout-textarea-min-height: 200px;
}
