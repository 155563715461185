.dialog {
  .secondary-action {
    color: var(--ion-color-medium-tint);
  }

  .primary-action {
    color: var(--ion-color-primary-shade);
  }
}

