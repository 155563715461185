.progress-bar-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;

  &.on-earning-tabs {
    top: 102px;
  }
}
